import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import styled from 'styled-components';
import ContactSection from '../components/ContactSection';
import SEO from '../components/SEO';

const ContactPageBackground = styled.div`
  background: var(--charcoal);
  padding-bottom: 5vh;
  @media (min-width: 1027px) and (max-width: 1360px) {
    padding-bottom: 10vh;
  }
`;

export default function ContactPage({ data, location }) {
  return (
    <>
      <SEO title={'Contact Us'} />
      <ContactPageBackground>
        <Hero
          bg={data.sanityContactPage}
          heading={data.sanityContactPage.name}
          subText={''}
          bgAlt={data.sanityContactPage.headerImage.altText}
          location={location}
        />
        <ContactSection />
      </ContactPageBackground>
    </>
  );
}

export const { data } = graphql`
  query ContactPageQuery {
    sanityContactPage(id: { eq: "-f04df878-43ad-561d-a5c1-7f25a53fbf53" }) {
      headerImage {
        asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], aspectRatio: 1.7778)
        }
        altText
      }
      name
    }
  }
`;
