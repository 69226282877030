/* eslint-disable prettier/prettier */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import MapboxGL from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const StyledContent = styled.section`
  background: var(--lightgrey);
  h3 {
    padding: 3rem 0;
    text-align: center;
  }
  .map-container {
    height: 580px;
    .map {
      height: 580px;
      width: 100%;
    }
    @media (min-width: 1024px) {
      height: 678px;
    }
    display: grid;
    .background-image,
    .map {
      width: 100%;
      height: auto;
      grid-area: 1/1;
      object-position: 0 0;
    }
    button {
      padding: 1px 6px;
      box-shadow: none;
      color: var(--black);
    }
  }
`;

// Pull data from Sanity with a graphql query
// create an GEOJSON object with an image, address, title, description and link for each project
// Convert Address to Geolocation coordinates
//

export default function CountyMapSection() {
  const countyMapRef = useRef();

  MapboxGL.accessToken =
    'pk.eyJ1IjoiY2FuZHJlczQxIiwiYSI6ImNrcXNnamJ6NDJxZXoyb28xeWl1andpYWoifQ.WmJA5O1HE-fgfuDgOaPkig';
  useEffect(() => {
    const map = new MapboxGL.Map({
      container: countyMapRef.current,
      style: 'mapbox://styles/candres41/cktw3ulce0emj19puj1qvsf2h',
      center: [-121.69502716064453, 37.23577030970146],
      zoom: 5.5,
    });
  }, [countyMapRef]);

  return (
    <StyledContent>
      <div className="map-container">
        <div className="map" ref={countyMapRef} />
      </div>
    </StyledContent>
  );
}
