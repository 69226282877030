import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { ImPhone } from 'react-icons/im';
import { IoIosMail } from 'react-icons/io';
import CountyMapSection from './CountyMapSection';
import { motion } from 'framer-motion';

const StyledContactSection = styled.section`
  transform: translateY(-140px);
  @media (min-width: 820px) {
    transform: translateY(-150px);
  }
  margin: 0 auto;
  text-align: center;
  background: var(--white);
  max-width: 1280px;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 65%);
  .branding {
    width: 80%;
    max-width: 300px;
    margin-bottom: 3rem;
  }
  .map {
    width: 100%;
  }
  @media (min-width: 768px) {
    top: 45%;
  }
  width: 90%;
  h2 {
    margin: 2rem 0;
    font-weight: regular;
    --minFontSize: 20px;
    --maxFontSize: 25px;
    --scaler: 5vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  }
  .contact-container {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 820px) {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
    width: 100%;
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
      color: var(--charcoal);
      transition: color ease 0.2s;
    }
    a:hover {
      transition: color ease 0.2s;
      color: var(--midgrey);
    }
    padding: 2rem 0;
  }
  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: Poppins;
    font-size: 2rem;
    @media (min-width: 920px) {
      height: 60%;
    }
  }
  .map-section {
    width: 100%;
    position: relative;
  }
  .counties-served {
    bottom: -120px;
    right: 50%;
    transform: translateX(50%);
    position: absolute;
    width: 80%;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    background: var(--white);
    @media (min-width: 820px) {
      bottom: unset;
      top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .county-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    flex-wrap: wrap;
    width: 95%;
    margin-top: 0;
    padding: 0;
    gap: 10px;
    @media (min-width: 820px) {
      flex-direction: row;
      justify-content: space-evenly;
      row-gap: 10px;
    }
    li {
    }
  }
  .phone-link {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  a {
  }
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

const countyVariants = {
  countyOffscreen: {
    y: 300,
    x: '50%',
    opacity: 0,
  },
  countyOnscreen: {
    y: 0,
    x: '50%',
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

export default function ContactSection({ ContactData }) {
  return (
    <StyledContactSection>
      <motion.section
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        transition={{ delay: 0.3 }}
        className="contact-container"
      >
        <motion.div variants={itemVariants} className="contact-info">
          <motion.div variants={itemVariants} className="image-wrapper">
            <StaticImage
              className="branding"
              src="../images/branding.png"
              alt="SMS Logo"
            />
          </motion.div>
          <motion.div variants={itemVariants} className="contact-text">
            <motion.p variants={itemVariants}>Hollister, CA</motion.p>
            <motion.p variants={itemVariants}>
              <a
                href="tel:18316359090"
                rel="noreferer noopener"
                className="phone-link"
              >
                <span>(831)635-9090</span>
              </a>
            </motion.p>
            <motion.p variants={itemVariants}>
              <a href="mailto:info@sms13.com" rel="noreferer noopener">
                {` `}info@sms13.com
              </a>
            </motion.p>
          </motion.div>
        </motion.div>
        <motion.div variants={itemVariants} className="map-section">
          <motion.div variants={countyVariants} className="counties-served">
            <motion.h2 variants={countyVariants}>Counties Served</motion.h2>
            <motion.ul
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              transition={{ delay: 0.3, staggerChildren: 0.2 }}
              className="county-list"
            >
              <motion.li variants={itemVariants}>Alameda</motion.li>
              <motion.li variants={itemVariants}>Contra Costa</motion.li>
              <motion.li variants={itemVariants}>Merced</motion.li>
              <motion.li variants={itemVariants}>Monterey</motion.li>
              <motion.li variants={itemVariants}>San Benito</motion.li>
              <motion.li variants={itemVariants}>San Mateo</motion.li>
              <motion.li variants={itemVariants}>Santa Clara</motion.li>
              <motion.li variants={itemVariants}>Santa Cruz</motion.li>
            </motion.ul>
          </motion.div>
          <CountyMapSection />
        </motion.div>
      </motion.section>
    </StyledContactSection>
  );
}
